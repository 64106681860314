import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { Checklist } from '../entities/checklist.entity';

class ChecklistService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Checklist) {
    return http.post('/checklist', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Checklist) {
    return http.put(`/checklist/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(checklistId: string) {
    return http.delete(`/checklist/${checklistId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllChecklists(payload: any = {}) {
    return http
      .get('/checklist', {
        params: payload,
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getAllChecklistsPaginted(payload: any = {}) {
    return http
      .get('/checklist', {
        params: { ...payload, paginate: 1 },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getChecklistById(id: string) {
    return http.get(`/checklist/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}
const simplePaginationService = new SimplePagination();
export default new ChecklistService(simplePaginationService);
