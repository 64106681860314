
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SimplePagination extends Vue {
  goToFirst() {
    this.$emit('go-to-first');
  }

  back() {
    this.$emit('back');
  }

  next() {
    this.$emit('next');
  }
}
