import store from '@/store';
import ChecklistService from '../services/checklist.service';

export class Checklist {
  public id: string = '';

  public description: string = '';

  public status_name: string = '';

  public active: boolean | number = false;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public company: any;

  public statusId: string = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.status_name = data.status_name || '';
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.statusId = data.status ? data.status.id : '';
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
      status_id: this.statusId,
    };
  }

  public save() {
    return ChecklistService.create(this.format());
  }

  public update() {
    return ChecklistService.update(this.format());
  }

  public delete() {
    return ChecklistService.delete(this.id);
  }
}
