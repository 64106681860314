
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Checklist } from '../../entities/checklist.entity';
import ChecklistService from '../../services/checklist.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class ChecklistListComponent extends Vue {
  private checklists: Checklist[] = [];

  private get headers(): any {
    const items = [
      { text: 'Descrição', value: 'description', width: '50%' },
      {
        text: 'Status',
        value: 'activeText',
      },
      {
        text: 'Status Cliente',
        value: 'status_name',
      },
      {
        text: 'Ações',
        width: '10%',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(checklist: Checklist) {
    this.$router.push({
      name: 'checklist-edit',
      params: {
        id: checklist.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'checklist-create',
    });
  }

  private async getChecklists() {
    const checklists = await ChecklistService.getAllChecklistsPaginted();
    this.checklists = checklists.map((checklist: Checklist) => new Checklist(checklist));
  }

  public async backPagination() {
    if (!ChecklistService.simplePaginationService.prevLink) return;
    const checklists = await ChecklistService.simplePaginationService.prev();
    this.checklists = checklists.map((checklist: Checklist) => new Checklist(checklist));
  }

  public async nextPagination() {
    if (!ChecklistService.simplePaginationService.nextLink) return;
    const checklists = await ChecklistService.simplePaginationService.next();
    this.checklists = checklists.map((checklist: Checklist) => new Checklist(checklist));
  }

  public async goToFirst() {
    if (!ChecklistService.simplePaginationService.firstLink) return;
    const checklists = await ChecklistService.simplePaginationService.goToFirst();
    this.checklists = checklists.map((checklist: Checklist) => new Checklist(checklist));
  }

  private async created() {
    this.getChecklists();
  }
}
